<template>
  <div>
    <vx-card v-if="!addChildStoryForm" class="mt-5 mb-5">
      <h2>{{ childName }}'s Story</h2>
      <p>Tell your {{ childName }}'s story by answering a series of questions. The questionnaire will take approximately
        20
        minutes.</p>
      <div v-if="storyId">
        <vs-button color="primary" type="filled" class="mt-10"
                   @click="$router.push({ name: 'edit-contributor-child-story', params: { id: $route.params.id } })">
          Edit
        </vs-button>
      </div>
      <div v-else>
        <vs-button color="primary" type="filled" class="mt-10" @click="addChildStoryClick(true)">
          Start
        </vs-button>
      </div>
      <vs-button color="primary" type="filled" class="mt-10"
                 @click="$router.push({ name: 'view-child-story', params: { childId: childId,contributorId:contributorId} })">
        View Other Stories
      </vs-button>
    </vx-card>
    <vx-card v-if="stories && stories.childstories && addChildStoryForm" class="mt-5 mb-5">
      <h2>{{ childName }}'s Story</h2>

      <div v-if="stories">
        <div class="child-story-steps">
          <ul>
            <li v-for="index in stories.childstories.length+1" :key="index" :class="stepClass(index-1)">
              <span>{{ index }}</span></li>
          </ul>
        </div>

        <!--For form-->
        <div v-for="(story,index) in stories.childstories" :key="story._id" v-if="!isPreview">
          <div v-show="index == currentIndex">
            <span class="story-instruction">{{ index + 1 + '. ' + story.instruction }}</span>
            <div v-if="story.answerType == 'text'" class="mt-5">
              <vs-textarea counter="600" v-model="answers[index].story"
                           class="mt-2"/>
            </div>
            <div v-else>
              <div class="w-full mb-5 story-img">
                <template v-if="answers[index].dataImg">
                  <vs-avatar :src="answers[index].dataImg"/>
                  <div class="modify-img flex my-5">
                    <input
                      type="file"
                      class="hidden"
                      :ref="'uploadImgInput' + story._id"
                      @change="updateCurrImg($event,index)"
                      accept="image/*"
                    />
                    <vs-button href="#" @click="displayRef('uploadImgInput' + story._id)">Update Image</vs-button>
                    <vs-button
                      color="danger"
                      class="ml-4"
                      @click="answers[index].dataImg = null; answers[index].story = null"
                    >Remove Image
                    </vs-button>
                  </div>
                </template>

                <div class="upload-img mt-5" v-if="!answers[index].dataImg">
                  <input
                    type="file"
                    class="hidden"
                    :ref="'uploadImgInput' + story._id"
                    @change="updateCurrImg($event,index)"
                    accept="image/*"
                  />
                  <vs-button type="border" @click="displayRef('uploadImgInput' + story._id)">Upload Photo</vs-button>
                </div>
              </div>
            </div>
            <span v-if="error" style="color: red">{{ error }}</span>
          </div>
        </div>

        <div v-if="isPreview" class="mt-5">
          <h4 class="mb-2 text-black"><strong>{{ stories.childstories.length }} . Review your child's story</strong>
          </h4>
          <div class="">
            <div v-for="(story,index) in stories.childstories" :key="story._id" v-if="isPreview">
              <h4 class="mb-2 text-black"><strong>{{ index + 1 + '. ' + answers[index].instruction }}</strong></h4>
              <p v-if="story.answerType == 'text'" class="mb-2"> {{ answers[index].story }}</p>
              <p v-else class="story-img"><img :src="answers[index].dataImg"></p>
            </div>
          </div>
        </div>

        <div class="flex" style="margin-top: 45px">
          <vs-button color="primary" type="border" @click="previous" v-show="currentIndex != 0">
            Previous
          </vs-button>
          <vs-button color="primary" type="filled" @click="next" v-show="!isPreview" class="ml-auto">
            Next
          </vs-button>
          <vs-button color="primary" type="filled" @click="saveStory" v-show="isPreview" class="ml-auto">
            Submit
          </vs-button>
          <vs-button color="primary" type="filled" @click="cancelChildStory" class="ml-auto">
            Cancel
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      addChildStoryForm: false,
      stories: {},
      childId: null,
      childName: null,
      contributorId: null,
      answers: [],
      dataImg: "",
      storyId: null,
      error: null,
      currentIndex: 0,
      isPreview: false,

    };
  },
  methods: {
    ...mapActions("parent", [
      "getChildStoryForContributor",
      "saveChildStoryForContributor",
      "getContributorDetail"
    ]),
    cancelChildStory() {
      this.addChildStoryForm = false;
      this.isPreview = false;
      this.currentIndex = 0;
    },
    addChildStoryClick(value) {
      this.addChildStoryForm = value;
    },
    stepClass(index) {
      if (index < this.currentIndex) {
        return "step-complete";
      } else if (index == this.currentIndex) {
        return "step-complete last";
      } else {
        return "";
      }
    },
    previous() {
      if (this.isPreview) {
        this.error = null;
        this.isPreview = false;
      } else {
        this.error = null;
        this.currentIndex--;
      }
    },
    next() {
      this.error = null;
      if (this.currentIndex != (this.stories.childstories.length - 1)) {
        if (
          (this.answers[this.currentIndex].story != null
            && this.answers[this.currentIndex].story != "") ||
          (this.answers[this.currentIndex].dataImg != null
            && this.answers[this.currentIndex].dataImg != "")
        ) {
          this.currentIndex++;
        } else {
          this.error = "Please fill the field.";
        }
      } else {
        this.isPreview = true;
      }

    },
    displayRef(ref) {
      this.$refs[ref][0].click()
    },
    saveStory() {
      this.error = null;
      if (
        (this.answers[this.currentIndex].story != null
          && this.answers[this.currentIndex].story != "") ||
        (this.answers[this.currentIndex].dataImg != null
          && this.answers[this.currentIndex].dataImg != "")
      ) {
        let self = this;
        this.$vs.loading();
        let data = new FormData();
        data.append(`contributorId`, this.$route.params.id);
        data.append(`contributorName`, this.contributor.fullName);
        data.append(`childId`, self.stories.childId);
        data.append(`fullName`, self.stories.fullName);

        _.forEach(self.answers, function (value, key) {
          data.append(`presetId[${key}]`, value.presetId);
          data.append(`order[${key}]`, value.order);
          data.append(`type[${key}]`, value.type);
          data.append(`instruction[${key}]`, value.instruction);
          data.append(`story[${key}]`, value.story);
        });

        this.saveChildStoryForContributor(data)
          .then((res) => {
            this.$vs.loading.close();
            this.addChildStoryForm = false;
            this.storyId = res.data.data._id;
            this.$vs.notify({
              title: 'Success',
              text: "Story added successfully",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: 'Failure',
              text: "Something went wrong",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });

      } else {
        this.error = "Please fill the field.";
      }
    },
    async updateCurrImg(input, index) {
      if (input.target.files && input.target.files[0]) {
        this.answers[index].story = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.answers[index].dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  async mounted() {
    this.$vs.loading();
    await this.getContributorDetail(this.$route.params.id)
      .then(async (res) => {
        this.$vs.loading.close();
        this.contributor = res.data.data;
        this.storyId = this.contributor.storyId;
        this.childId = this.contributor.childId._id;
        this.childName = this.contributor.childId.fullName;
        this.contributorId = this.contributor._id;
      })
    this.$vs.loading.close();

    await this.getChildStoryForContributor(this.$route.params.id)
      .then(async (res) => {
        let self = this;
        console.log(res.data.data ,'asd')
        self.stories = await res.data.data[0];
        this.$emit('setContributorLearningCenter', self.stories.learningCenter && self.stories.learningCenter.length > 0 ?  self.stories.learningCenter[0] : null);
         _.forEach(self.stories.childstories, function (value, key) {
          const id = value._id;
          const obj = {
            presetId: id,
            order: key + 1,
            type: value.answerType[0],
            instruction: value.instruction,
            story: null,
            dataImg: null
          }
          self.answers.push(obj);
        });

      })
      .catch((err) => {
        //console.error(err)
      });
  }

};
</script>
<style lang="scss" scoped>
.story-form {
  max-width: 900px;
}

.story-img {
  max-width: 500px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.story-instruction {
  margin-bottom: 15px;
  display: block;
}
</style>

